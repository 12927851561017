import React, { useEffect, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import useProfile from 'src/hooks/useProfile';
import parse from 'url-parse';
import * as Sentry from '@sentry/react-native';
import { fonts } from 'src/constants/theme';
import useTheme from 'src/hooks/useTheme';
import { useFetch } from 'src/hooks/useFetch';
import { fetchAlerts } from 'src/lib/api/contentful';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import { isElectron, isWeb } from 'src/utils';
import { useAlertStatusHandler } from 'src/hooks/useAlertStatusHandler';
import { useAuth } from 'src/hooks/useAuth';
import { isEmpty } from 'lodash';
import { useSafeArea } from 'react-native-safe-area-context';
import { routes } from './routes';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import useInitialUrl from 'src/hooks/useInitialUrl';
import { INTEGRATIONS } from 'src/constants';

const Stack = createStackNavigator();

const AuthStack = () => {
  const { companyInfo, token } = useProfile();
  const { theme, isDarkMode, colors: themeColors } = useTheme();
  const { loginWithParams } = useAuth();
  const [loading, setLoading] = useState(false);
  const saveProfile = useDispatchMapper(updateProfileAction);
  // since we have useLinking from react-navigation, window.location.href gets changed
  // useInitialUrl hook helps us to retrieve window.location.href
  const { url: windowLocationHref } = useInitialUrl();
  const searchQueryParams = parse(windowLocationHref, true)?.query || {};
  const { connectionKey, connectionType, connectionEmail } = searchQueryParams;

  const handleCloudRadialAuth = async () => {
    try {
      const res = await chatgenieAPI.cloudRadialAuth(connectionKey);
      const { access_token: accessToken } = res.data || {};

      if (!accessToken) throw new Error('API returned empty token');

      saveProfile({ token: accessToken, loggedIn: true, connectionType });
    } catch (err) {
      Sentry.captureException(new Error(`CouldRadialAuth failed: ${err.response?.data?.message || err.message}`));
    }
  };

  const handleMessengerCustomAuth = async () => {
    try {
      // Send connectionKey as a hash used to verify the connection
      const res = await chatgenieAPI.messengerCustomAuth(connectionEmail, connectionKey);
      const { access_token: accessToken } = res.data;

      if (!accessToken) throw new Error('API returned empty token');

      saveProfile({ token: accessToken, loggedIn: true, connectionType });
    } catch (err) {
      Sentry.captureException(new Error(`MessengerCustomAuth failed: ${err.message}`));
    }
  };

  const handleSetFlow = async () => {
    const flow = await window?.ipc?.invoke('get-flow');
    if (flow) {
      setLoading(true);
      loginWithParams({ flow })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isElectron()) {
      handleSetFlow();
    } else if (isWeb()) {
      if (window?.location?.href) {
        const parsedUrl = parse(window.location.href, true);
        if (!isEmpty(parsedUrl?.query)) {
          setLoading(true);
          loginWithParams(parsedUrl.query)
            .catch(() => {
              setLoading(false);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
    try {
      Sentry.setContext('company', {
        appId: companyInfo?.appId,
        name: companyInfo?.name,
        domain: isWeb() ? document?.referrer : null,
      });
    } catch (err) {
      console.log('Sentry error:', err);
    }
  }, []);

  useEffect(() => {
    if (!connectionKey || token) return;

    if (connectionType === INTEGRATIONS.CLOUD_RADIAL) {
      handleCloudRadialAuth();
    }
    if (connectionType === INTEGRATIONS.MESSENGER_AUTH) {
      handleMessengerCustomAuth();
    }
  }, [connectionKey, connectionType]);

  const { data, isLoading } = useFetch(
    isElectron() && [reactQueryKeys.ALERTS, { parentId: companyInfo?.parentId }],
    fetchAlerts,
    {
      refetchInterval: isWeb() ? 15000 : 60000,
      refetchIntervalInBackground: true,
    },
  );

  const insets = useSafeArea();

  useAlertStatusHandler({ alerts: data, alertLoading: isLoading });

  if (loading) return null;
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={{
        animationTypeForReplace: 'pop',
        headerTitleAlign: 'center',
        headerStyle: {
          backgroundColor: isDarkMode ? themeColors.foreground : '#FEFEFE',
          height: insets.top + 56,
          borderBottomWidth: 0,
          shadowColor: '#000',
          shadowOffset: {
            height: 0,
            width: 0,
          },
          shadowRadius: 2,
          shadowOpacity: 0.1,
        },
        headerTitleStyle: {
          color: theme.primaryColor,
          fontSize: 20,
          fontWeight: '700',
          fontFamily: fonts.regular,
          display: 'flex',
          alignItems: 'center',
        },
        cardStyle: {
          flex: 1,
        },
      }}
    >
      {routes.auth.map((stackRoute) => (
        <Stack.Screen key={stackRoute.name} {...stackRoute} />
      ))}
      {/* <Auth /> */}
    </Stack.Navigator>
  );
};

export default AuthStack;
