import { StyleSheet } from 'react-native';
import { colors, fonts } from 'src/constants/theme';
import { isWeb } from 'src/utils';

export const markdownStyles = {
  heading: { marginBottom: 20, maxWidth: '100%' },
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 14,
    fontFamily: 'Inter-Regular',
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },
  body: { maxWidth: '100%' },
  textgroup: { maxWidth: '100%' },
  link: {
    ...(isWeb() && { wordBreak: 'break-word' }),
    color: colors.darkblue2,
    textDecoration: 'underline',
  },
  code: {
    backgroundColor: colors.grey20,
    color: colors.blue,
    border: `1px solid ${colors.grey6}`,
    padding: '0 1px',
  },
  mentions: {
    display: 'inline-block',
    fontFamily: fonts.regular,
    transition: 'background 200ms ease, color 200ms ease',
    cursor: 'pointer',
    borderRadius: 4,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
};

export const getMarkdownMentionStyles = ({ isHovered, isContact }) => ({
  ...markdownStyles.mentions,
  ...(isContact
    ? // at-mentions markdown for contacts
      {
        backgroundColor: isHovered ? colors.mentions.blue3 : colors.mentions.blue2,
        color: colors.mentions.blue,
      }
    : // at-mentions markdown for members
      {
        backgroundColor: isHovered ? colors.mentions.green3 : colors.mentions.green2,
        color: isHovered ? colors.mentions.green4 : colors.mentions.green,
      }),
});

const styles = StyleSheet.create({
  title: { lineHeight: 30 },
  wrapper: {
    maxWidth: '85%',
    minWidth: '35%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
    marginHorizontal: 12,
    backgroundColor: 'transparent',
  },
  mineWrapper: { alignSelf: 'flex-end', justifyContent: 'flex-end', maxWidth: '75%' },
  messageBox: {
    position: 'relative',
    backgroundColor: colors.white,
    marginLeft: 8,
    paddingTop: 0,
    paddingHorizontal: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: 6,
    alignSelf: 'flex-start',
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: colors.grey20,
  },
  company: { marginBottom: 7 },
  systemMessage: { marginVertical: 15 },
  nextMyMessage: { marginBottom: 1, marginHorizontal: 10 },
  myMessage: { backgroundColor: '#E1ECFE', borderWidth: 0 },
  timestamp: {
    position: 'absolute',
    bottom: 5,
    right: 7,
  },
  fileAttachment: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingTop: 8,
    paddingBottom: 18,
    height: 46,
  },
  fileNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileName: {
    marginLeft: 8,
  },
  attachmentDesc: {},
  downloadWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 3,
  },
  downloadIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 15,
    height: 15,
    marginRight: 4,
    borderWidth: 1,
    borderColor: colors.grey8,
    borderStyle: 'solid',
    borderRadius: 15,
    backgroundColor: 'transparent',
  },
});

export const getReplySpacerStyle = (quickRepliesLength = 0) => ({
  height: quickRepliesLength * (36 + 8) || 30,
  backgroundColor: 'transparent',
  maxHeight: 100,
});

export const getMyMessageStyle = (primaryOpacity) => ({
  backgroundColor: primaryOpacity,
  borderWidth: 0,
  marginLeft: 0
});

export const getMessageStyle = ({ isMine, isDarkMode, theme, themeColors, isTextMessage }) => [
  styles.messageBox,
  isMine && getMyMessageStyle(theme.primaryOpacity),
  isDarkMode && { borderWidth: 0, backgroundColor: themeColors.foreground },
  isTextMessage && { flexGrow: 1, paddingTop: 8, paddingHorizontal: 15, paddingBottom: 20 },
];

export const getTimestampStyle = ({ isImage }) => [
  styles.timestamp,
  isImage && {
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 2,
  },
];

export const getAttachmentContainerStyle = (width) => ({
  width,
  backgroundColor: 'transparent',
});

export const getCompanyStyles = (isAttachment) => [
  styles.company,
  isAttachment && {
    paddingHorizontal: 15,
    paddingTop: 8,
  },
];

export const getAnimationStyles = ({ isImage, width, height, opacity }) => ({
  height,
  width,
  justifyContent: 'center',
  alignItems: isImage ? 'center' : 'stretch',
  backgroundColor: '#efeeea',
  opacity,
});

export const getFileAttachmentStyles = ({ isMine, isImage, theme, colors }) => [
  styles.fileAttachment,
  {
    paddingTop: !isMine && !isImage ? 0 : 8,
    backgroundColor: isMine ? theme.primaryOpacity : colors.white,
  },
];

export const getAvatar = (companyInfo) =>
  companyInfo.botImage ? { uri: companyInfo.botImage } : require('src/assets/images/avatar.png');

export default styles;
