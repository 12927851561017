import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { colors } from 'src/constants/theme';
import useProfile from 'src/hooks/useProfile';
import styles from './style';
import MarkdownDisplay from './MarkdownDisplay';

const Text = ({ message, messageTimeLabel }) => {
  const { companyInfo } = useProfile();
  const isMine = message?.isMine;
  const author = message?.attributes?.author;
  const botName = companyInfo?.botName || 'Thread';
  const isBot = !author || author === botName;
  
  return (
    <>
      {!isMine && (
        <Common.Text style={styles.company}>
          <Common.Text weight="bold" style={styles.title}>
            {author || botName}
          </Common.Text>
          {isBot ? <Common.Text color={colors.grey}> (bot)</Common.Text> : null}
        </Common.Text>
      )}
      <MarkdownDisplay text={message.message || ''} />
      {messageTimeLabel}
    </>
  );
};

Text.propTypes = {
  message: PropTypes.object.isRequired,
};

export default memo(Text);
