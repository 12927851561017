import React, { useState } from 'react';
import StepIndicator from './StepIndicator';
import { styles } from './style';
import { Animated, Text, View } from 'react-native';
import { useEffect } from 'react';
import useTranslation from 'src/hooks/useTranslation';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

const RESPONDED_PROGRESSBAR_TOP_DELAY = 4000;

export default function LiveThreadStatusStepper({
  withDetails = true,
  ticket,
  setShowProgressAtTop,
  hasMemberMessage,
  channel,
  isRequestThread = false,
}) {
  const { translate } = useTranslation();
  const { assignee, statusObject, member } = ticket || {};

  const statuses = {
    default: {
      step: 0,
      title: translate('liveStep.inactive.default.title'),
      desc: isRequestThread ? '' : translate('liveStep.inactive.default.desc'),
    },
    assigned: {
      step: 0.5,
      title: translate('liveStep.active.getAssignee.title', assignee),
      desc: isRequestThread
        ? translate('liveStep.active.getAssignee.request.desc')
        : translate('liveStep.active.getAssignee.desc', assignee),
    },
    responded: {
      step: 1,
      title: translate('liveStep.active.getAssignee.isHere', assignee),
    },
  };

  const [stepStatus, setStepStatus] = useState(statuses.default);
  const opacityAnim = React.useRef(new Animated.Value(1)).current;
  const transformAnim = React.useRef(new Animated.Value(0)).current;
  const isClosed = Boolean(statusObject?.closed_status);
  const isStatusChanged = !statusObject?.is_default;
  const threadRespondedAndAssigned = hasMemberMessage && assignee;

  useEffect(() => {
    opacityAnim.setValue(0);
    transformAnim.setValue(10);
    Animated.parallel([
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
      }),
      Animated.timing(transformAnim, {
        toValue: 0,
        duration: 600,
        useNativeDriver: false,
      }),
    ]).start();
  }, [stepStatus.step]);

  useEffect(() => {
    let newStepStatus = stepStatus;
    if (assignee) {
      newStepStatus = statuses.assigned;
    }
    if (threadRespondedAndAssigned) {
      newStepStatus = statuses.responded;
    }
    if (isClosed) {
      newStepStatus.step = 2;
    }
    setStepStatus({ ...newStepStatus });
  }, [ticket, channel, threadRespondedAndAssigned]);

  useEffect(() => {
    if (threadRespondedAndAssigned) {
      const timeout = setTimeout(() => {
        setShowProgressAtTop(true);
      }, RESPONDED_PROGRESSBAR_TOP_DELAY);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [threadRespondedAndAssigned]);

  return (
    <View style={styles.container}>
      {withDetails && !isClosed && (
        <Animated.View
          style={[
            {
              opacity: opacityAnim,
              transform: [
                {
                  translateY: transformAnim,
                },
              ],
            },
          ]}
        >
          {assignee && (
            <View style={styles.avatarContainer}>
              <UserProfilePicture size={64} alt={assignee} url={member?.profile_image?.path} />
            </View>
          )}
          <View
            style={[
              styles.details,
              {
                marginTop: assignee ? 36 : 10,
              },
            ]}
          >
            <Text style={styles.title}>{stepStatus.title}</Text>
            <Text style={styles.desc}>{stepStatus.desc}</Text>
          </View>
        </Animated.View>
      )}
      <View style={styles.stepIndicator}>
        <StepIndicator currentPosition={stepStatus.step} />
      </View>
    </View>
  );
}
