import React, { useRef, useState } from 'react';
import { headerStyles } from './style';
import Common, { TouchableOpacity } from 'src/components/Common';
import Sparkles from './Sparkles';
import useTypewriter from '../../hooks/useTypewriter';
import useTheme from 'src/hooks/useTheme';
import { useEffect } from 'react';
import useProfile from 'src/hooks/useProfile';
import { LIVE_CHAT_PREFIX } from 'src/utils';

const MAX_WAIT_TIME = 30000;

export default function Summary({ threadSummary, isNewThread }) {
  const { theme } = useTheme();
    const {
      companyInfo: { isAutoTitleGenerationEnabled = false },
    } = useProfile();
  const hasLongEnoughSummary = threadSummary?.replace(LIVE_CHAT_PREFIX, "").length > 10;
  const isEligibleForTitleGeneration = isNewThread && isAutoTitleGenerationEnabled && hasLongEnoughSummary;
  const [isGenerating, setIsGenerating] = useState(isEligibleForTitleGeneration);
  const [summary, setSummary] = useState(threadSummary);
  const waitTimeout = useRef(null);

  const { start } = useTypewriter({
    timeout: 20,
    onType: (partialSummary) => setSummary(partialSummary),
    throttledCharCount: 100,
    onFinish: () => {
      setIsGenerating(false);
    },
  });

  const endAnimation = (newSummary) => {
    setIsGenerating(false);
    start(newSummary);
    clearTimeout(waitTimeout.current);
  };

  useEffect(() => {
    if (isEligibleForTitleGeneration) {
      if (waitTimeout.current) {
        endAnimation(threadSummary);
        return;
      }
      waitTimeout.current = setTimeout(() => {
        setIsGenerating(false);
      }, MAX_WAIT_TIME);

      return;
    }

    setSummary(threadSummary);
  }, [threadSummary]);

  return (
    <>
      {isGenerating ? (
        <Common.View style={headerStyles.generatePlaceholder}>
          <Sparkles />
          <Common.Text style={headerStyles.generatePlaceholderText}>Generating title...</Common.Text>
        </Common.View>
      ) : (
          <Common.Text numberOfLines={1} color={theme.primaryColor} style={headerStyles.summary}>
            {summary}
          </Common.Text>
      )}
    </>
  );
}
