import React, { memo, useEffect, useState, useRef } from 'react';
import { Animated, Image, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { colors } from 'src/constants/theme';
import useTheme from 'src/hooks/useTheme';
import useProfile from 'src/hooks/useProfile';
import { isElectron, isWeb } from 'src/utils';
import styles, {
  getAnimationStyles,
  getAttachmentContainerStyle,
  getCompanyStyles,
  getFileAttachmentStyles,
} from './style';
import { responsiveWidth } from 'src/utils/responsive';
import { DocumentIcon, DownloadIcon, ImageIcon, DownloadArrowIcon } from 'src/assets/icons';
import { TouchableOpacity } from 'src/components/Common';
import webchatIframe, { isInIframe } from 'src/utils/webchatIframe';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { openPopupAction } from 'src/redux/popup/popupActions';
import useTranslation from 'src/hooks/useTranslation';
import { downloadFile } from 'src/utils/file.utils';

const calculateResponsiveImageDimensions = (url, nativeWidth = 1, nativeHeight = 1, isImage = true) => {
  const MAX_IMAGE_HEIGHT = 180;
  const MAX_IMAGE_WIDTH = responsiveWidth(70);

  if (!isImage) {
    return {
      width: responsiveWidth(50),
      url: '',
    };
  }
  let height = MAX_IMAGE_HEIGHT;
  let width = (MAX_IMAGE_HEIGHT * nativeWidth) / nativeHeight;
  const isWiderThanMax = width > MAX_IMAGE_WIDTH;
  if (isWiderThanMax) {
    width = MAX_IMAGE_WIDTH;
    height = (width * nativeHeight) / nativeWidth;
  }
  return {
    width: width || MAX_IMAGE_WIDTH,
    height,
    url,
  };
};

const Attachment = ({ message, attachment, author, isMine, isImage, messageTimeLabel }) => {
  const { companyInfo } = useProfile();
  const openPopup = useDispatchMapper(openPopupAction);
  const { theme } = useTheme();
  const { translate } = useTranslation();
  const botName = companyInfo?.botName || 'Thread';
  const isBot = !author || author === botName;

  const fileName = message?.filename || attachment?.file?.name;
  const downloadTxt = translate('common.text.download');
  const [attachmentProperties, setAttachmentProperties] = useState(
    calculateResponsiveImageDimensions(
      attachment?.preview,
      attachment?.width || message?.width,
      attachment?.height || message?.height,
      isImage,
    ),
  );
  const mediaUrlTimestamp = useRef();

  const getMediaUrl = () =>
    new Promise((resolve, reject) => {
      message
        .getContentTemporaryUrl()
        .then((url) => {
          mediaUrlTimestamp.current = new Date().getTime();
          resolve(url);
        })
        .catch(() => reject());
    });

  const checkMediaUrlValidation = () =>
    new Promise((resolve, reject) => {
      const currentTime = new Date().getTime();
      const difference = (currentTime - mediaUrlTimestamp.current) / 1000; // second
      const isInvalid = difference > 300; // 300s expiring duration
      if (isInvalid) {
        getMediaUrl()
          .then((url) => {
            resolve(url);
          })
          .catch(() => reject());
      } else {
        resolve(attachmentProperties.url);
      }
    });

  const handleDocumentDownload = async () => {
    if (isWeb() && attachmentProperties.url) {
      const url = await checkMediaUrlValidation();
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = fileName;
      link.click();
    }
  };

  const handleImageDownload = async () => {
    if (isElectron()) {
      const imageUrl = await checkMediaUrlValidation();
      downloadFile(imageUrl, fileName);
    } else {
      handleDocumentDownload();
    }
  };

  const handleClickPhoto = async () => {
    const imageUrl = await checkMediaUrlValidation();
    const payload = { imageUrl, imageName: fileName, downloadTxt };
    const isInsideMSTeams = isWeb() && !!document.getElementById('messenger');
    if (isInIframe() && !isInsideMSTeams) {
      webchatIframe.openPhotoPreviewModal(payload);
      return;
    }
    if (isElectron()) {
      window?.ipc?.invoke('modal-window-open', payload);
    } else {
      openPopup({ data: payload });
    }
  };

  useEffect(() => {
    // if attachment is first message in new ticket, we already have local file
    // so we don't fetch attachment url
    if (!attachment) {
      getMediaUrl()
        .then((url) => {
          if (isImage && !message?.width && !message?.height) {
            Image.getSize(url, (nativeWidth, nativeHeight) => {
              const calculatedData = calculateResponsiveImageDimensions(url, nativeWidth, nativeHeight);
              setAttachmentProperties(calculatedData);
            });
          } else {
            setAttachmentProperties((old) => ({ ...old, url }));
          }
        })
        .catch((err) => console.log('err', err));
    }
  }, []);

  // Fade in-out animation for image loading
  const opacity = useRef(new Animated.Value(1)).current;
  const { current: anim } = useRef(
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 0.4,
          duration: 1000,
        }),
        Animated.timing(opacity, {
          toValue: 1,
          duration: 1000,
        }),
      ]),
    ),
  );

  useEffect(() => {
    if (isImage) {
      anim.start();
    }
  }, [opacity]);

  const imageAttachment = attachmentProperties.url ? (
    <Pressable onPress={handleClickPhoto} style={{ backgroundColor: 'rgb(250, 249, 246)' }}>
      <Image
        style={{ height: attachmentProperties.height, width: attachmentProperties.width, borderRadius: 6 }}
        source={{ uri: attachmentProperties.url }}
        resizeMode="cover"
        onLoad={() => {
          anim.stop();
          opacity.setValue(1);
        }}
      />
      {messageTimeLabel}
    </Pressable>
  ) : (
    <ImageIcon />
  );

  const imageDownloadBtn = (
    <Pressable onPress={handleImageDownload}>
      <Common.View style={styles.downloadWrapper}>
        <Common.View style={styles.downloadIcon}>
          <DownloadArrowIcon />
        </Common.View>
        <Common.Text color={colors.grey8}>{downloadTxt}</Common.Text>
      </Common.View>
    </Pressable>
  );

  const fileAttachment = (
    <Common.View style={getFileAttachmentStyles({ isMine, isImage, theme, colors })}>
      <Common.Text style={styles.fileNameContainer} numberOfLines={1}>
        <DocumentIcon style={{ minWidth: 16 }} />
        <Common.Text numberOfLines={1} style={styles.fileName}>
          {fileName}
        </Common.Text>
      </Common.Text>
      {attachmentProperties.url && (
        <TouchableOpacity onPress={handleDocumentDownload}>
          <Common.Text>
            <DownloadIcon />
          </Common.Text>
        </TouchableOpacity>
      )}
      {messageTimeLabel}
    </Common.View>
  );

  return (
    <>
      <Common.View style={getAttachmentContainerStyle(attachmentProperties.width)}>
        {!isMine ? (
          <Common.Text style={getCompanyStyles(true)}>
            <Common.Text weight="bold" style={styles.title}>
              {author || botName}
            </Common.Text>
            {isBot ? <Common.Text color={colors.grey}> (bot)</Common.Text> : null}
          </Common.Text>
        ) : null}
        <Animated.View style={getAnimationStyles({ ...attachmentProperties, isImage, opacity })}>
          {isImage ? imageAttachment : fileAttachment}
        </Animated.View>
        {isImage && imageDownloadBtn}
      </Common.View>
    </>
  );
};

Attachment.propTypes = {
  message: PropTypes.object.isRequired,
  attachment: PropTypes.object,
  author: PropTypes.string,
  isMine: PropTypes.bool,
  isImage: PropTypes.bool,
};

export default memo(Attachment);
