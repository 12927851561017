import React from 'react';
import { styles } from './style';
import Hoverable from 'src/components/Hoverable';
import { Pressable } from 'react-native';
import Common from 'src/components/Common';
import Svg, { Path } from 'react-native-svg';
import { colors } from 'src/constants/theme';
import useTranslation from 'src/hooks/useTranslation';

export const MentionsLoadingIndicator = () => {
  const { translate } = useTranslation();
  const noopArr = [1, 2, 3, 4];

  return (
    <div style={styles.mentionsLoadingContainer}>
      <p style={styles.mentionsLoadingTitle}>{translate('mentions.header.title')}</p>
      {noopArr.map((_, idx) => (
        <div key={idx} style={styles.mentionsLoadingSkeleton}>
          <div style={styles.loadingCircle}></div>
          <div style={styles.loadingRect}></div>
        </div>
      ))}
    </div>
  );
};

export const MentionsTagButton = ({ onPress, isDisabled }) => {
  const getStrokeColor = (isBtnHovered) => {
    if (isDisabled) return colors.grey6;
    if (isBtnHovered) return colors.darkblue2;
    return colors.grey8;
  };

  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable style={styles.mentionsTagBtn} onPress={onPress} disabled={isDisabled}>
          <Common.Text>
            <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_11338_27413)">
                <Path
                  d="M9.49967 12.6663C11.2486 12.6663 12.6663 11.2486 12.6663 9.49967C12.6663 7.75077 11.2486 6.33301 9.49967 6.33301C7.75077 6.33301 6.33301 7.75077 6.33301 9.49967C6.33301 11.2486 7.75077 12.6663 9.49967 12.6663Z"
                  stroke={getStrokeColor(isHovered)}
                  strokeWidth="1.64147"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M12.6663 6.33349V10.2918C12.6663 10.9217 12.9166 11.5258 13.362 11.9712C13.8074 12.4166 14.4115 12.6668 15.0413 12.6668C15.6712 12.6668 16.2753 12.4166 16.7207 11.9712C17.1661 11.5258 17.4163 10.9217 17.4163 10.2918V9.50016C17.4162 7.71339 16.8117 5.9792 15.701 4.57958C14.5903 3.17996 13.0389 2.19721 11.2989 1.79115C9.55884 1.38508 7.73263 1.57957 6.11716 2.34299C4.5017 3.10642 3.192 4.39387 2.40101 5.99602C1.61003 7.59817 1.38428 9.42078 1.76048 11.1675C2.13668 12.9142 3.0927 14.4823 4.4731 15.6168C5.85349 16.7513 7.57707 17.3854 9.36357 17.4162C11.1501 17.4469 12.8944 16.8723 14.313 15.786"
                  stroke={getStrokeColor(isHovered)}
                  strokeWidth="1.64147"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </Svg>
          </Common.Text>
        </Pressable>
      )}
    </Hoverable>
  );
};
