import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';
import { INPUT_AREA_HEIGHT } from 'src/components/Chatbox/MessageInput/style';

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    bottom: INPUT_AREA_HEIGHT,
    right: 0,
    left: 0,
    marginVertical: 7,
    alignItems: 'center',
  },
  innerContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  poweredByStyles: {
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    right: 0,
    width: '100%',
    left: 0,
    flexDirection: 'row',
    position: 'absolute',
  },
  avatarList: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 12,
    backgroundColor: 'transparent',
  },
  typingText: {
    fontSize: 14,
    paddingLeft: 6,
    fontStyle: 'italic',
    margin: 0,
    color: colors.grey8,
  },
});
export const getInnerContentStyle = ({ typingFadeAnim, typingTranslateAnim }) => [
  styles.innerContent,
  {
    opacity: typingFadeAnim,
    transform: [
      {
        translateX: typingTranslateAnim,
      },
    ],
  },
];
export const getAvatarStyle = ({ index }) => ({
  marginLeft: index && -10,
  borderWidth: 1,
  borderColor: colors.backgroundColor,
  borderRadius: 12,
});
export const getPowerByStyles = ({ powerByFadeAnim, powerByTranslateAnim }) => [
  styles.poweredByStyles,
  {
    opacity: powerByFadeAnim,
    transform: [
      {
        translateY: powerByTranslateAnim,
      },
    ],
  },
];
export default styles;
