import { fonts, colors } from 'src/constants/theme';
import { INPUT_AREA_HEIGHT } from 'src/components/Chatbox/MessageInput/style';

export const styles = {
  // react-mentions styles overrides
  mentionsWrapper: {
    flexGrow: 1,
    height: '100%',
    minHeight: INPUT_AREA_HEIGHT,
    paddingTop: 6,
    paddingBottom: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    fontSize: 14,
    fontFamily: fonts.regular,
    // should be static, because mentions loader is absolute
    position: 'static',
  },
  mentionStyle: {
    fontFamily: fonts.regular,
    backgroundColor: colors.grey20,
    color: 'transparent',
    borderRadius: 4,
  },
  mentionInput: {
    backgroundColor: '#fff',
    // at-mentions "@" btn is 36px wide
    width: 'calc(100% - 36px)',
    control: {},
    input: {
      border: 'none',
      padding: 0,
      overflowY: 'auto',
    },
    highlighter: {
      // border: '1.5px inset transparent',
      maxHeight: 119,
      border: 'none',
    },
    '&singleLine': {},

    suggestions: {
      left: -82,
      marginTop: 0,
      right: 'auto',
      top: 'auto',
      bottom: 0,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px -5px 10px -10px',
      list: {
        maxHeight: 150,
        overflowY: 'scroll',
        backgroundColor: 'white',
        borderBottom: '1px solid #EFEFEE',
        fontSize: 14,
      },
      item: {
        padding: '5px 10px',
        '&focused': {
          backgroundColor: '#EFEEEA',
        },
      },
    },
  },

  // at-mentions tag button
  mentionsTagBtn: {
    paddingLeft: 6,
    paddingRight: 11,
    paddingTop: 18,
    paddingBottom: 15,
  },
  // suggestions list
  suggestionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  suggestionLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '58%',
  },
  suggestionLabelCircle: {
    marginRight: 10,
  },
  suggestionStatusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  suggestionStatusMember: {
    fontSize: 12,
    color: colors.mentions.green,
    backgroundColor: colors.mentions.green2,
    marginRight: 8,
    borderRadius: 5,
    padding: 3,
  },
  suggestionInThreadStatus: {
    fontSize: 12,
    color: colors.grey8,
  },
  suggestionHeaderTitle: {
    color: '#666666',
    paddingLeft: 10,
    fontWeight: 'bold',
  },

  // loading indicator styles
  mentionsLoadingContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '100%',
    left: 0,
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px -5px 10px -10px',
    borderBottom: '1px solid #EFEFEE',
    maxHeight: 182,
    overflowY: 'hidden',
  },
  mentionsLoadingTitle: {
    color: '#666666',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
    padding: '10px 10px 5px 10px',
  },
  mentionsLoadingSkeleton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5.25px 10px',
  },
  loadingRect: {
    height: 25,
    flexGrow: 1,
    backgroundColor: '#ececec',
  },
  loadingCircle: {
    width: 30,
    height: 30,
    backgroundColor: '#ececec',
    borderRadius: '50%',
    marginRight: 10,
  },
};

export const getMentionInputStyles = (maxAreaWidth, inputHeight) => {
  // 1 line of input is filled
  let bottomOffset = 44;

  // 2 lines of input are filled
  if (inputHeight > 25) {
    bottomOffset = 53;
  }
  // 3 lines of input are filled
  if (inputHeight > 50) {
    bottomOffset = 60;
  }
  // more than 3 lines
  if (inputHeight > 65) {
    bottomOffset = inputHeight + 6;
  }

  return {
    ...styles.mentionInput,
    suggestions: {
      ...styles.mentionInput.suggestions,
      // suggestions block should take maximum width of widget screen
      width: maxAreaWidth,
      bottom: bottomOffset,
    },
  };
};

export const getMentionsWrapperStyles = (sendBtnWidth) => {
  return {
    ...styles.mentionsWrapper,
    // mentions wrapper includes mentions input and btn "@"
    // 45px for attachment btn
    maxWidth: `calc(100% - 46px - ${sendBtnWidth}px)`,
  };
};
