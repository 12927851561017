import React, { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput as ReactMentionsInput } from 'react-mentions';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { reactiveVarClient } from '@Thread-Magic/thread-service-utils';
import { GET_MENTION_SUGGESTIONS } from 'src/graphql/queries';
import {
  MentionsLoadingIndicator,
  MentionsTagButton,
} from 'src/components/Chatbox/MessageInput/Mentions/mention-input.utils';
import { getMentionInputStyles, getMentionsWrapperStyles, styles } from './style';
import Common from 'src/components/Common';
import useTranslation from 'src/hooks/useTranslation';
import { MENTIONS_REGEX } from 'src/utils/mention.utils';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

const normalizeMentions = (data) => {
  let mentions = data?.mentionAutocomplete?.collection || [{}];
  mentions = mentions.filter((m) => m.type !== 'special');
  return mentions.map((user) => ({ display: user.label, id: user.id, ...user }));
};

/**
 *
 * @param parentInputProps - has placeholder, onSubmit and onChange events
 * @param isDisabled - if input should be disabled
 * @param maxAreaWidth - overall area of footer includes ("attachment", "@" and "send" buttons)
 * @param sendBtnWidth - width of "Send" btn in pixels
 * @param sendBtnRef - reference for "Send" btn used to clear input value
 * @returns {JSX.Element}
 * @constructor
 */
export const MentionInput = ({ parentInputProps, isDisabled, maxAreaWidth, sendBtnWidth, sendBtnRef }) => {
  const [inputValue, setInputValue] = useState('');
  const apolloClient = reactiveVarClient();
  const { chatState } = useChatState();
  const { id: threadId } = chatState.activeTicket || {};
  const mentionInputRef = useRef();
  const debounceTimer = useRef(null);
  const [mentionSuggestionsLoading, setMentionSuggestionsLoading] = useState(false);
  const { translate } = useTranslation();
  // @ is available after thread is created
  const isMentionsTagDisabled = isDisabled || !threadId;
  const inputHeight = mentionInputRef.current?.inputElement?.clientHeight || 20;

  const getMentionSuggestionsQuery = async (search) => {
    try {
      setMentionSuggestionsLoading(true);
      const res = await apolloClient.query({
        query: GET_MENTION_SUGGESTIONS(),
        variables: { search, threadId, isInternal: false },
        fetchPolicy: 'network-only',
      });
      return normalizeMentions(res.data);
    } catch (_) {
      return [];
    } finally {
      setMentionSuggestionsLoading(false);
    }
  };

  const handleMentionsInputChange = (e, newValue, newPlainTextValue, mentions) => {
    const originalString = e.target.value;
    // value for mentions input
    setInputValue(originalString);

    // for special case originalString: <!here|here>
    const pattern = new RegExp(MENTIONS_REGEX.SPECIAL);
    const modifiedString = originalString.replace(pattern, '<!$1>');
    // value to submit becomes: <!here>
    parentInputProps.onChangeText(modifiedString);
  };

  const handleAtMentionsTagClick = () => {
    setInputValue((prev) => {
      if (!prev?.length) return '@';
      const lastSymbol = prev[prev.length - 1];
      if (lastSymbol !== '@') return prev + ' @';
      return prev;
    });
    mentionInputRef.current?.inputElement?.focus();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      parentInputProps.onSubmitEditing();
      setInputValue('');
    }
  };

  const handleMentionTextAdd = () => {};

  const fetchMentionSuggestions = async (query, callback) => {
    if (!threadId) return;
    setMentionSuggestionsLoading(true);
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(async () => {
      const data = await getMentionSuggestionsQuery(query);
      callback(data);
    }, 300);
  };

  const renderCustomSuggestionsContainer = (children) => (
    <Common.View style={{ paddingTop: 10 }} transparent>
      <Common.View transparent>
        <Common.Text style={styles.suggestionHeaderTitle}>{translate('mentions.header.title')}</Common.Text>
      </Common.View>
      <Common.View style={{ paddingTop: 5 }} transparent>
        {children}
      </Common.View>
    </Common.View>
  );

  const renderSuggestion = (mentionData) => {
    const { label, description, inThread, userType, profileImage } = mentionData || {};
    const txtInThread = translate('mentions.status.inThread');
    const txtNotInThread = translate('mentions.status.notInThread');
    const txtMember = translate('mentions.status.memberText');
    const isMember = userType === 'member';

    return (
      <Common.View style={styles.suggestionWrapper} transparent>
        <Common.View style={styles.suggestionLabelWrapper} transparent>
          <UserProfilePicture url={profileImage?.path} size={30} alt={label} style={styles.suggestionLabelCircle} />
          <Common.Text numberOfLines={1}>{description}</Common.Text>
        </Common.View>
        <Common.View transparent style={styles.suggestionStatusWrapper}>
          {isMember && <Common.Text style={styles.suggestionStatusMember}>{txtMember}</Common.Text>}
          <Common.Text style={styles.suggestionInThreadStatus}>{inThread ? txtInThread : txtNotInThread}</Common.Text>
        </Common.View>
      </Common.View>
    );
  };

  useEffect(() => {
    const handleSendBtnClick = () => {
      setInputValue('');
    };

    if (sendBtnRef?.current && sendBtnRef.current.addEventListener) {
      sendBtnRef.current.addEventListener('click', handleSendBtnClick);
    }

    return () => {
      sendBtnRef?.current?.removeEventListener('click', handleSendBtnClick);
    };
  }, []);

  return (
    <Common.View style={getMentionsWrapperStyles(sendBtnWidth)}>
      <MentionsTagButton onPress={handleAtMentionsTagClick} isDisabled={isMentionsTagDisabled} />
      {mentionSuggestionsLoading && <MentionsLoadingIndicator />}
      <ReactMentionsInput
        ref={mentionInputRef}
        singleLine={false}
        style={getMentionInputStyles(maxAreaWidth || 0, inputHeight)}
        placeholder={parentInputProps.placeholder}
        value={inputValue}
        onChange={handleMentionsInputChange}
        onKeyDown={handleKeyPress}
        disabled={isDisabled}
        forceSuggestionsAboveCursor={true}
        customSuggestionsContainer={renderCustomSuggestionsContainer}
        data-testid={parentInputProps.testID}
      >
        <Mention
          data={fetchMentionSuggestions}
          trigger="@"
          markup="<__id__|__display__>"
          displayTransform={(id, display) => `@${display}`}
          style={styles.mentionStyle}
          onAdd={handleMentionTextAdd}
          renderSuggestion={renderSuggestion}
          appendSpaceOnAdd={true}
        />
      </ReactMentionsInput>
    </Common.View>
  );
};
